import { ApiResponse } from 'endpoints/endpoint';
import { events } from 'endpoints/index';

import api from '..';

export const eventsAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getEventsList: builder.query<
      ApiResponse<events.GetWithFiltersResult, events.GetWithFiltersError>,
      events.GetWithFiltersParams
    >({
      query: (params) => events.getWithFilters(params).args,
      providesTags: (result) =>
        result?.success
          ? [...result.events.map(({ _id }) => ({ type: 'EVENTS' as const, id: _id })), { type: 'EVENTS', id: 'LIST' }]
          : [{ type: 'EVENTS', id: 'LIST' }],
    }),
    getEventsListForAutoComplete: builder.query<
      ApiResponse<events.GetWithFiltersResult, events.GetWithFiltersError>,
      events.GetWithFiltersParams
    >({
      query: (params) => events.getWithFilters(params).args,
      providesTags: (result) =>
        result?.success
          ? [
              ...result.events.map(({ _id }) => ({ type: 'EVENTS_FOR_AUTOCOMPLETE' as const, id: _id })),
              { type: 'EVENTS_FOR_AUTOCOMPLETE', id: 'LIST' },
            ]
          : [{ type: 'EVENTS_FOR_AUTOCOMPLETE', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});
