import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'constants/index';

import 'client/styles/index.scss';

import { MAIN_PAGE_ROUTE, IFRAME_ROUTE } from 'client/constants/routes';
import { useAppSelector, useTranslation, useResponsive } from 'client/hooks';
import { selectSettings } from 'client/redux/settings/selectors';

import Main from './pages/Main';
import Iframe from './pages/Iframe';

import shareImage from 'client/assets/shareImage/share.jpg';
import favicon from 'client/assets/favicon/favicon.png';

import css from './App.module.scss';

const App: React.FC = () => {
  const { translate } = useTranslation('seo');
  const settings = useAppSelector(selectSettings);
  const [isMobile] = useResponsive(['MOBILE', 'TABLET']);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [themeMode, setThemeMode] = React.useState('light');
  const location = useLocation();

  React.useEffect(() => {
    const root = document.documentElement;
    const isIframe = location.pathname === IFRAME_ROUTE;

    root.style.setProperty('--borderColor', settings.design.borderColor);
    root.style.setProperty('--buttonsColor', settings.design.buttonsColor);
    root.style.setProperty('--filterSectionBg', settings.design.filterSectionBg);
    root.style.setProperty('--iconsColor', settings.design.iconsColor);

    setIsInitialized(true);

    if (isMobile && window.matchMedia && !isIframe) {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => onModeChange(e));

      const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      root.style.setProperty('--filterSectionBg', isDarkMode ? '#141414' : settings.design.filterSectionBg);

      root.dataset.theme = isDarkMode ? 'dark' : 'light';
      setThemeMode(isDarkMode ? 'dark' : 'light');
    }

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', onModeChange);
    };
  }, [isMobile]);

  const onModeChange = (e: any) => {
    const root = document.documentElement;

    const isDarkMode = e.matches;
    root.dataset.theme = isDarkMode ? 'dark' : 'light';
    setThemeMode(isDarkMode ? 'dark' : 'light');
    root.style.setProperty('--filterSectionBg', isDarkMode ? '#141414' : settings.design.filterSectionBg);
  };

  const renderHelmet = () => {
    const canonicalUrl = `${CONFIG.api.split('/api')[0]}`;
    return (
      <>
        <Helmet>
          <title>{translate('title')}</title>
          <link rel="icon" type="image/png" href={favicon} />
          <link rel="canonical" href={canonicalUrl} />
          <meta name="description" content={translate('description')} />
          <meta property="og:title" content={translate('ogTitle')} />
          <meta property="og:description" content={translate('ogDescription')} />
          <meta property="og:image" content={shareImage} />
        </Helmet>
      </>
    );
  };

  return (
    <div className={css.app}>
      {renderHelmet()}

      {isInitialized && (
        <Switch>
          <Route exact path={MAIN_PAGE_ROUTE}>
            <Main themeMode={themeMode} />
          </Route>
          <Route exact path={IFRAME_ROUTE}>
            <Iframe />
          </Route>
          <Redirect to={MAIN_PAGE_ROUTE} />
        </Switch>
      )}
    </div>
  );
};

export default App;
