import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import { useAppSelector, useTranslation } from 'client/hooks';
import { Event } from 'models/index';

import { selectSettings } from 'client/redux/settings/selectors';

import Icon from '../Icon';
import { Loader } from '../Loader';
import { EventItem } from './EventItem';

import css from './EventList.module.scss';

interface Props {
  events: Event.Model[];
  isAmountVisible: boolean;
  fundedEvents: Event.Model[];
  isBusy: boolean;
  isNotFoundMessageVisible: boolean;
}

export const EventList: FC<Props> = (props): JSX.Element => {
  const { events, isBusy, isNotFoundMessageVisible, isAmountVisible, fundedEvents } = props;
  const { translate } = useTranslation('general');
  const [isUpStageArrow, setIsUpStageArrow] = useState<boolean>(false);
  const settings = useAppSelector(selectSettings);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = (): void => {
    if (window.scrollY < 300) {
      setIsUpStageArrow(false);
    } else if (!isUpStageArrow) {
      setIsUpStageArrow(true);
    }
  };

  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={css.container}>
      {isBusy ? (
        <Loader className={css.loader} />
      ) : (
        <>
          {events.length !== 0 && isAmountVisible && (
            <div className={css.containerHeader}>
              <span>{translate('eventsSizeInfo', { size: `${events.length}` })}</span>
            </div>
          )}

          {isUpStageArrow && (
            <div className={css.upStage}>
              <Icon color={settings.design.iconsColor} type="upScrollArrow" onClick={scrollToTop} />
            </div>
          )}

          <div className={css.containerList}>
            {_.map(events, (event) => (
              <EventItem key={event._id} event={event} />
            ))}

            {events.length === 0 && isNotFoundMessageVisible && (
              <>
                <div className={css.emptyListMessage}>
                  <Icon type="notFound" color={settings.design.iconsColor} className={css.icon} />
                  <p className={css.text}>{translate('notFoundText')}</p>
                </div>
                {fundedEvents.length !== 0 && (
                  <div className={css.recommendationsTitle}>{translate('recommendationsTitle')}</div>
                )}
                {_.map(fundedEvents, (event) => (
                  <EventItem key={event._id} event={event} />
                ))}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
