import { Admin } from 'models/index';
import { endpoint, EndpointError } from '../endpoint';

export type ReadParams = Pick<Admin.IAdmin, '_id'>;
export type ReadResult = Admin.IAdmin;
export type ReadError = EndpointError;

export const read = endpoint<ReadParams, ReadResult, ReadError>({
  method: 'GET',
  url: (params) => `/admin/${params._id}`,
});
