import { createSelector } from 'reselect';
import _ from 'lodash';

import { RootState } from '..';

export const selectSettings = (state: RootState) => {
  return state.settings;
};

export const selectLang = (state: RootState) => {
  return state.settings.lang;
};

export const selectTexts = createSelector(selectSettings, selectLang, (settings, lang) => {
  return settings.texts[lang];
});

export const selectLoginTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'logIn', {});
});

export const selectGeneralTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'general', {});
});

export const selectSeoTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'seo', {});
});

export const selectFilterTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'filter', {});
});
