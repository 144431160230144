import { createSlice } from '@reduxjs/toolkit';

interface LoginPopUpState {
  isShowLoginPopUp: boolean;
}

const initialState: LoginPopUpState = {
  isShowLoginPopUp: false,
};

export const loginPopUp = createSlice({
  name: 'loginPopUp',
  initialState,
  reducers: {
    showPopUp: (state) => {
      state.isShowLoginPopUp = true;
    },
    hidePopUp: (state) => {
      state.isShowLoginPopUp = false;
    },
  },
});

export const { showPopUp, hidePopUp } = loginPopUp.actions;
export default loginPopUp.reducer;
