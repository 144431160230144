import * as yup from 'yup';

import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type DeleteParams = { id: string };
export type DeleteResult = { id: string };
export type DeleteError = EndpointError;

export const deleteParamsSchema = yup.object().shape({
  id: yup.string().required(VALIDATION_ERROR.REQUIRED),
} as ValidationShape<DeleteParams>);

export const deleteItem = endpoint<DeleteParams, DeleteResult, DeleteError>({
  method: 'DELETE',
  url: () => '/event',
});
