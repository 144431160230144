import * as React from 'react';

import css from './IntroSection.module.scss';
import { useResponsive } from 'client/hooks';

interface Props {
  className?: string;
  bgMobile: string;
  bgDesktop: string;
  // title: string;
  // description: string;
}

export const IntroSection: React.FC<Props> = (props) => {
  const [isMobile] = useResponsive(['MOBILE', 'TABLET']);
  const { className = '', bgMobile, bgDesktop /* title, description */ } = props;
  return (
    <div className={css.introSection}>
      <div
        className={css.cover}
        style={isMobile ? { backgroundImage: `url(${bgMobile})` } : { backgroundImage: `url(${bgDesktop})` }}
      >
        {/* <div className={css.titleWrapper}>
          <h1 className={css.title}>{title}</h1>
          <h4 className={css.description}>{description}</h4>
        </div> */}
      </div>
    </div>
  );
};
