import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth, getRedirectResult } from 'firebase/auth';

import { app as firebaseApp } from 'src/firebase-client';
import { signIn } from './sign-in';

export const checkRedirectSignIn = createAsyncThunk<boolean, void, { rejectValue: string }>(
  'admin/checkSignInRedirect',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const firebaseAuth = getAuth(firebaseApp);
      const redirectResult = await getRedirectResult(firebaseAuth);

      console.log('redirectResult', redirectResult);

      if (redirectResult) {
        const user = redirectResult.user;

        await dispatch(
          signIn({
            uid: user.uid,
            name: user.displayName || '',
            email: user.email || '',
            pictureURL: user.photoURL || '',
          }),
        );

        return true;
      }
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }

    return false;
  },
);
