import React, { useEffect } from 'react';
import cn from 'classnames';
// import { useResponsive } from 'client/hooks';
import Icon from '../Icon';

import css from './GoogleAds.module.scss';

interface Props {
  id: string;
  path: string;
  size: any[];
  minHeight: number;
  className?: string;
  enableCloseButton?: boolean;
}
const GoogleAds: React.FC<Props> = (props) => {
  const { path, size, id, minHeight, className, enableCloseButton = false } = props;
  const [isClosed, setIsClose] = React.useState(false);
  const [isInited, setIsInited] = React.useState(false);

  useEffect(() => {
    if (path && size && id) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useDfpSlot(path, size, id);
    }
  }, []);

  const useDfpSlot = (pathVal: string, sizeVal: any[], idVal: string) => {
    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(function createSlot() {
      googletag.defineSlot(pathVal, sizeVal, idVal).addService(googletag.pubads());
      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });
    googletag.cmd.push(function displayAds() {
      googletag.display(idVal);
    });

    setTimeout(() => {
      const ads = document.getElementById(`google_ads_iframe_${idVal}_0__container__`);
      if (ads?.hasChildNodes()) {
        setIsInited(true);
      }
    }, 1000);
  };

  // return <div id={id} style={{ width: '100%', height: `${minHeight}px` }} className={cn(css.googleAds, className)} />;
  return (
    <>
      {!isClosed && (
        <>
          {enableCloseButton && isInited && (
            <div className={css.closeButton} onClick={() => setIsClose(true)}>
              <Icon type="close" />
            </div>
          )}
          <div id={id} className={cn(className)} />
        </>
      )}
    </>
  );
};

export default GoogleAds;
