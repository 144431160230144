import * as yup from 'yup';

import * as Settings from 'models/settings';
import { ValidationShape, ERROR as VALIDATION_ERROR } from 'endpoints/validation';

import { endpoint, EndpointError } from '../endpoint';

export type UpdateParams = Partial<Settings.Model>;
export type UpdateResult = Partial<Settings.Model>;
export type UpdateError = EndpointError;

export const updateParamsSchema = yup.object().shape({
  general: yup
    .object()
    .shape({
      age: yup.array(yup.string()),
      geographic: yup.array(yup.string()),
      eventType: yup.array(yup.string()),
    })
    .required(VALIDATION_ERROR.REQUIRED),
} as ValidationShape<UpdateParams>);

export const update = endpoint<UpdateParams, UpdateResult, UpdateError>({
  method: 'POST',
  url: () => `/settings`,
});
