import React from 'react';
import cn from 'classnames';

import Icon from '../Icon';

import css from './CheckboxIcon.module.scss';

interface Props {
  className?: string;
  isSmall?: boolean;
  checked: boolean;
  color: string;
  borderColor?: string;
}

export const CheckboxIcon: React.FC<Props> = ({ className = '', checked, color, borderColor, isSmall = false }) => {
  const checkboxBorderColor = borderColor ? borderColor : color;
  return (
    <div className={cn(css.checkboxIcon, isSmall && css.small, className)}>
      <Icon
        className={css.icon}
        color={checked ? color : checkboxBorderColor}
        type={checked ? 'checkbox-checked' : 'checkbox-empty'}
      />
    </div>
  );
};
