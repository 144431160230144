export const ANONYMOUS_UID = 'anonymous';

export const SHARE_CONSTANTS = {
  WHATSAPP: 'WhatsApp',
  TWITTER: 'Twitter',
  MAIL: 'Mail',
  FACEBOOK: 'Facebook',
};

export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);