import * as yup from 'yup';

import { Event } from 'models/index';

import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape } from '../validation';

export type GetWithFiltersParams = {
  eventType?: number[];
  search?: string;
  area?: number[];
  age?: number[];
  fromDate?: string;
  toDate?: string;
  isOnlyFreeEvents?: boolean;
};
export type GetWithFiltersResult = { events: Event.Model[] };
export type GetWithFiltersError = EndpointError;

export const getWithFiltersParamsSchema = yup.object().shape({
  eventType: yup.array().of(yup.number()),
  area: yup.array().of(yup.number()),
  fromDate: yup.string(),
  date: yup.object({
    toDate: yup.string(),
    search: yup.string(),
  }),
  isOnlyFreeEvents: yup.boolean(),
  age: yup.array().of(yup.number()),
} as ValidationShape<GetWithFiltersParams>);

export const getWithFilters = endpoint<GetWithFiltersParams, GetWithFiltersResult, GetWithFiltersError>({
  method: 'GET',
  url: () => '/event/get-with-filters',
});
