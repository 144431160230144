import * as yup from 'yup';

import { Event } from 'models/index';
import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR } from '../validation';
import * as Pagination from '../pagination';

export type PaginationParams = Pagination.Params<Omit<Event.Model, 'id'>, null>;
export type PaginationResult = Pagination.Result<Event.Model>;
export type PaginationError = EndpointError;

export const paginationParamsSchema = yup.object().shape({
  categoryId: yup.string().required(ERROR.REQUIRED),
  page: yup.number().positive(ERROR.INVALID_VALUE).required(ERROR.REQUIRED),
  pageSize: yup.number().positive(ERROR.INVALID_VALUE).required(ERROR.REQUIRED),
  search: yup.string(),
  sort: yup.object().shape({
    field: yup.string().oneOf(['title', 'createdAt'], ERROR.INVALID_VALUE),
    direction: yup.string().oneOf(['asc', 'desc'], ERROR.INVALID_VALUE),
  }),
} as ValidationShape<PaginationParams>);

export const paginate = endpoint<PaginationParams, PaginationResult, PaginationError>({
  method: 'POST',
  url: () => `/event/paginate`,
});
