import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Model as SettingsModel } from 'models/settings';
import * as actions from './actions';

type Settings = SettingsModel & {
  lang: 'he' | 'en';
  isBusy: boolean;
};

const initialState: Settings = {
  lang: 'he',
  texts: {},
  general: { age: [], geographic: [], eventType: [], iframeTitle: '', iframeSubtitle: '' },
  design: {
    filterSectionBg: '',
    iconsColor: '',
    borderColor: '',
    buttonsColor: '',
    desktopBg: '',
    mobileBg: '',
  },
  isBusy: false,
};

export const admin = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<actions.Init>) => {
      state.texts = action.payload.texts;
      state.general = action.payload.general;
      state.design = action.payload.design;
    },
  },
});

export const { init } = admin.actions;
export default admin.reducer;
