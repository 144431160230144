import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import _ from 'lodash';
import { useAppSelector, useResponsive /* useTranslation */ } from 'client/hooks';
import { selectSettings } from 'client/redux/settings/selectors';
import { eventsAPI } from 'client/redux/api/events';
import { isIOS } from 'utils/is-ios';
import { parseSearch } from 'utils/parse-react-router-search.js';
import { CAN_USE_DOM } from 'client/constants';

import { Event } from 'models/index';
import { Filter } from 'client/components/common/Filter';
import { initialState as initialFilterState } from 'client/components/common/Filter/reducer';
import { FiltersState } from 'client/components/common/Filter/reducer';
import { Header } from 'client/components/common/Header';
import { EventList } from 'client/components/common/EventList';
import { IntroSection } from 'client/components/common/IntroSection';
import { GoogleAds } from 'client/components/common';

import css from './Main.module.scss';

interface Props {
  className?: string;
  themeMode: string;
}

const Main: React.FC<Props> = (props) => {
  const { className = '', themeMode } = props;
  // const { translate } = useTranslation('general');
  const location = useLocation();
  const [isMobile] = useResponsive('MOBILE');
  const settings = useAppSelector(selectSettings);
  const [fundedEvents, setFundedEvents] = React.useState<Event.Model[]>([]);
  const [isFirstFetch, setIsFirstFetch] = React.useState(true);
  const [isAdsBannerShow, setIsAdsBannerShow] = React.useState(true);
  const [isFirstClickOnSearch, setIsFirstClickOnSearch] = React.useState(true);
  const [windowWidth, setWindowWidth] = React.useState(0);
  const { design } = settings;
  const [trigger, { data: response, isFetching, isUninitialized }] = eventsAPI.endpoints.getEventsList.useLazyQuery();
  const events = useMemo(() => (response?.success ? response.events : []), [response]);

  React.useEffect(() => {
    const searchParams = parseSearch(location.search);

    const convertTypes = (params: any) => {
      const age = params.age ? params.age.split(',') : initialFilterState.age;
      const area = params.area ? params.area.split(',') : initialFilterState.area;
      const eventType = params.eventType ? params.eventType.split(',') : initialFilterState.eventType;
      const search = params.search ? params.search : initialFilterState.search;
      const isOnlyFreeEvents = params.isOnlyFreeEvents
        ? params.isOnlyFreeEvents === 'true'
        : initialFilterState.isOnlyFreeEvents;
      const fromDate = params.fromDate ? params.fromDate : initialFilterState.date.fromDate;
      const toDate = params.toDate ? params.toDate : initialFilterState.date.toDate;

      return {
        age,
        area,
        eventType,
        search,
        isOnlyFreeEvents,
        fromDate,
        toDate,
      };
    };

    const convertedData = convertTypes(searchParams);
    trigger({ ...convertedData });

    if (CAN_USE_DOM) {
      setWindowWidth(window.innerWidth);
    }

    const timer = setTimeout(() => {
      setIsAdsBannerShow(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    if (CAN_USE_DOM && isMobile && !isAdsBannerShow) {
      const doc = document.getElementById('dcMaavaronDiv');

      if (doc) {
        doc.remove();
      }
    }
  }, [isAdsBannerShow]);

  React.useEffect(() => {
    if (events.length !== 0 && isFirstFetch) {
      setFundedEvents(_.filter(events, { isFunded: true }));
      setIsFirstFetch(false);
    }
  }, [events, isFirstFetch]);

  const onSearchClick = (params: FiltersState) => {
    if (isFirstClickOnSearch) {
      setIsFirstClickOnSearch(false);
    }

    trigger({ ...params.date, ..._.omit(params, 'date') });
  };

  return (
    <div className={cn(css.main, className)}>
      <div className={css.layout}>
        {typeof isMobile !== 'undefined' &&
          !isMobile &&
          CAN_USE_DOM &&
          (windowWidth >= 1920 ? (
            <GoogleAds
              id="ads.top"
              path="/6870/ynet/desktop/top/parents/children.events"
              size={[
                [970, 130],
                [970, 350],
                [1, 2],
                [1192, 90],
                [970, 100],
                [1192, 100],
                [1192, 130],
                [970, 90],
                [1, 1],
                [1192, 250],
                [1192, 350],
              ]}
              minHeight={350}
              className={css.googleAdsTopBanner}
            />
          ) : (
            <GoogleAds
              id="ads.top"
              path="/6870/ynet/desktop/top/parents/children.events"
              size={[
                [970, 130],
                [970, 350],
                // [1, 2],
                [970, 250],
                // [1192, 90],
                [970, 100],
                // [1192, 100],
                // [1192, 130],
                [970, 90],
                // [1, 1],
                // [1192, 250],
                // [1192, 350],
                // 'fluid',
              ]}
              minHeight={350}
              className={css.googleAdsTopBanner}
            />
          ))}

        {isMobile && CAN_USE_DOM && (
          <GoogleAds
            id={
              isIOS()
                ? '/6870/ynet/mobileweb_iphone/inboard/parents/children.events'
                : '/6870/ynet/mobileweb_android/inboard/parents/children.events'
            }
            path={
              isIOS()
                ? '/6870/ynet/mobileweb_iphone/inboard/parents/children.events'
                : '/6870/ynet/mobileweb_android/inboard/parents/children.events'
            }
            size={isIOS() ? [[350, 197], 'fluid', [320, 180]] : ['fluid', [350, 197], [320, 180]]}
            minHeight={600}
            enableCloseButton
            className={css.googleAdsTopBanner}
          />
        )}
        <Header themeMode={themeMode} />

        {/* <iframe
          src={`${window.location.origin}/iframe`}
          // src="https://kidsattractions.ynet.co.il/iframe"
          style={{ width: '100%', height: isRealMobile ? '400px' : '275px', border: 'none' }}
        /> */}

        <IntroSection
          bgMobile={typeof design.mobileBg === 'string' ? design.mobileBg : ''}
          bgDesktop={typeof design.desktopBg === 'string' ? design.desktopBg : ''}
          // title={translate('mainTitle')}
          // description={translate('mainDescription')}
        />

        <Filter onSearchBtnClick={onSearchClick} />

        {typeof isMobile !== 'undefined' && !isMobile && CAN_USE_DOM && (
          <div className={css.googleAdsAboveFilters}>
            <GoogleAds
              id="/6870/ynet/desktop/strip/parents/children.events"
              path="/6870/ynet/desktop/strip/parents/children.events"
              size={[
                [1192, 90],
                [970, 90],
              ]}
              minHeight={600}
            />
          </div>
        )}

        <EventList
          isAmountVisible={!isFirstClickOnSearch}
          fundedEvents={fundedEvents}
          events={events}
          isBusy={isFetching}
          isNotFoundMessageVisible={!isUninitialized}
        />
      </div>

      {/** Aside */}
      {typeof isMobile !== 'undefined' && !isMobile && CAN_USE_DOM && (
        <div className={css.googleAdsRightTop}>
          {windowWidth >= 1920 ? (
            <GoogleAds
              id="/6870/ynet/desktop/skyscraper/parents/children.events"
              path="/6870/ynet/desktop/skyscraper/parents/children.events"
              size={[
                [300, 600],
                [1, 1],
              ]}
              minHeight={600}
            />
          ) : (
            <GoogleAds
              id="/6870/ynet/desktop/skyscraper/parents/children.events"
              path="/6870/ynet/desktop/skyscraper/parents/children.events"
              size={[
                [160, 600],
                [120, 600],
              ]}
              minHeight={600}
            />
          )}
        </div>
      )}

      {isAdsBannerShow && typeof isMobile !== 'undefined' && CAN_USE_DOM && (
        <>
          {isMobile && CAN_USE_DOM && (
            <GoogleAds
              id={
                isIOS()
                  ? '/6870/ynet/mobileweb_iphone/interstitial.inner/parents/children.events'
                  : '/6870/ynet/mobileweb_android/interstitial.inner/parents/children.events'
              }
              path={
                isIOS()
                  ? '/6870/ynet/mobileweb_iphone/interstitial.inner/parents/children.events'
                  : '/6870/ynet/mobileweb_android/interstitial.inner/parents/children.events'
              }
              size={
                isIOS()
                  ? [
                      [1, 1],
                      [1, 2],
                    ]
                  : [
                      [1, 2],
                      [1, 1],
                    ]
              }
              minHeight={600}
              className={css.googleAdsBeforeLoading}
            />
          )}
          {/* {!isMobile && (
            <GoogleAds
              id="ads.interstitial"
              path="/6870/ynet/desktop/interstitial/parents/children.events"
              size={[
                // [970, 130],
                // [970, 350],
                // [970, 250],
                // [1192, 90],
                // [970, 100],
                // [1192, 100],
                // [1192, 130],
                // [970, 90],
                // [1192, 250],
                // [1192, 350],
                [2, 1],
                [1, 1],
              ]}
              minHeight={600}
              className={css.googleAdsBeforeLoading}
            />
          )} */}
        </>
      )}
    </div>
  );
};

export default Main;
