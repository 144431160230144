import * as yup from 'yup';

import { Event } from 'models/index';

import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type UpdateParams = Event.Model & {
  _id?: Event.Model['_id'];
  imageUrl?: File | string;
  title?: string;
  description?: string;
};
export type UpdateResult = { id: Event.Model['_id'] };
export type UpdateError = EndpointError;

export const updateParamsSchema = yup.object().shape({
  id: yup.string(),
  title: yup.string().required(VALIDATION_ERROR.REQUIRED),
  description: yup.string().required(VALIDATION_ERROR.REQUIRED),
  externalLink: yup.string(),
  externalLinkLabel: yup.string(),
  location: yup.string().required(VALIDATION_ERROR.REQUIRED),
  price: yup.string().required(VALIDATION_ERROR.REQUIRED),
  dates: yup
    .array(
      yup.object().shape({
        date: yup.string().required(VALIDATION_ERROR.REQUIRED).nullable(),
        additionalText: yup.string().nullable(),
      }),
    )
    .min(1, VALIDATION_ERROR.REQUIRED),
  geographicArea: yup.array(yup.string()).min(1, VALIDATION_ERROR.REQUIRED),
  age: yup.array(yup.string()).min(1, VALIDATION_ERROR.REQUIRED),
  eventTypes: yup.array(yup.string()).min(1, VALIDATION_ERROR.REQUIRED),
  credits: yup.string(),
  isPinned: yup.boolean(),
  isFunded: yup.boolean(),
  isFree: yup.boolean(),
  imageUrl: yup
    .mixed()
    .test('mimetype', VALIDATION_ERROR.INVALID_FORMAT, (value) => {
      if (typeof value === 'string') {
        return true;
      }

      if (
        ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg+xml'].includes(value?.mimetype || value?.type)
      ) {
        return true;
      }

      return false;
    })
    .test('size', VALIDATION_ERROR.INVALID_SIZE, (value) => {
      if (typeof value === 'string') {
        return true;
      }

      if (value && value.size && value.size <= 1024 * 1024 * 3) {
        return true;
      }

      return false;
    }),
} as unknown as ValidationShape<UpdateParams>);

export const update = endpoint<UpdateParams, UpdateResult, UpdateError>({
  method: 'POST',
  url: () => `/event`,
});
