import * as React from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import _ from 'lodash';
import { format, parseISO } from 'date-fns';

import { useAppSelector, useResponsive, useTranslation } from 'client/hooks';
import { selectSettings } from 'client/redux/settings/selectors';
import { parseSearch } from 'utils/parse-react-router-search.js';
import { dataLayerEvents } from 'utils/gtag';
import { isMobileOrTablet } from 'client/hooks/responsive';

import DatePicker from '../Datepicker';
import EFSelect from '../EFSelect';
import Checkbox from '../Checkbox';
import { Search } from '../Search';
import Button from '../Button';
import Icon from '../Icon';

import { reducer, initialState, FiltersState } from './reducer';

import css from './Filter.module.scss';

interface Props {
  className?: string;
  onSearchBtnClick: (params: FiltersState) => void;
  isIframe?: boolean;
}

export const Filter: React.FC<Props> = (props) => {
  const [isMobile] = useResponsive('MOBILE');
  const location = useLocation();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [isResetDatePicker, setResetDatePicker] = React.useState<boolean>(false);
  const { translate } = useTranslation('filter');
  const { className = '', onSearchBtnClick, isIframe = false } = props;
  const settings = useAppSelector(selectSettings);
  const isRealMobile = isMobileOrTablet();

  React.useEffect(() => {
    const searchParams = parseSearch(location.search);

    const convertTypes = (params: any) => {
      const age = params.age ? params.age.split(',') : initialState.age;
      const area = params.area ? params.area.split(',') : initialState.area;
      const eventType = params.eventType ? params.eventType.split(',') : initialState.eventType;
      const search = params.search ? decodeURIComponent(params.search) : initialState.search;
      const isOnlyFreeEvents = params.isOnlyFreeEvents
        ? params.isOnlyFreeEvents === 'true'
        : initialState.isOnlyFreeEvents;
      const date = {
        fromDate: params.fromDate ? params.fromDate : initialState.date.fromDate,
        toDate: params.toDate ? params.toDate : initialState.date.toDate,
      };

      return {
        age,
        area,
        eventType,
        search,
        isOnlyFreeEvents,
        date,
      };
    };
    const params = convertTypes(searchParams);
    dispatch({ type: 'setAll', payload: params });
  }, []);

  const ageSelectOption = [
    ..._.map(settings.general.age || [], (item, index) => {
      return { label: `${item}`, value: index };
    }),
  ];

  const areaSelectOption = [
    ..._.map(settings.general.geographic || [], (item, index) => {
      return { label: `${item}`, value: index };
    }),
  ];

  const activitySelectOption = [
    ..._.map(settings.general.eventType || [], (item, index) => {
      return { label: `${item}`, value: index };
    }),
  ];

  const onAgeChangeSelect = (value: number | number[]) => {
    if (_.isArray(value)) {
      dispatch({ type: 'setAge', payload: value });
    } else {
      if (value === -1) {
        dispatch({ type: 'setAge', payload: [] });
      } else {
        const next = [...state.age];
        const existingValueIndex = next.indexOf(value);

        if (existingValueIndex >= 0) {
          next.splice(existingValueIndex, 1);
        } else {
          next.push(value);
        }

        dispatch({ type: 'setAge', payload: next });
      }
    }
  };

  const onAreaChangeSelect = (value: number | number[]) => {
    if (_.isArray(value)) {
      dispatch({ type: 'setArea', payload: value });
    } else {
      if (value === -1) {
        dispatch({ type: 'setArea', payload: [] });
      } else {
        const next = [...state.area];
        const existingValueIndex = next.indexOf(value);

        if (existingValueIndex >= 0) {
          next.splice(existingValueIndex, 1);
        } else {
          next.push(value);
        }

        dispatch({ type: 'setArea', payload: next });
      }
    }
  };

  const onEventTypeChangeSelect = (value: number | number[]) => {
    if (_.isArray(value)) {
      dispatch({ type: 'setEventType', payload: value });
    } else {
      if (value === -1) {
        dispatch({ type: 'setEventType', payload: [] });
      } else {
        const next = [...state.eventType];
        const existingValueIndex = next.indexOf(value);

        if (existingValueIndex >= 0) {
          next.splice(existingValueIndex, 1);
        } else {
          next.push(value);
        }

        dispatch({ type: 'setEventType', payload: next });
      }
    }
  };

  const onSearchClick = () => {
    let gaData: {
      age?: string[];
      area?: string[];
      'activity type'?: string[];
      free_events_only: boolean;
      'free search'?: string;
      date?: string;
    } = {
      free_events_only: state.isOnlyFreeEvents,
    };
    if (!_.isEmpty(state.age)) {
      // const age = _.map(state.age, a => {
      //   const i = settings.general.age[a];
      //   return `${i.additionalText} ${i.from}-${i.to}`;
      // });
      const age = _.map(state.age, (a) => settings.general.age[a]);
      gaData = { ...gaData, age };
    }
    if (!_.isEmpty(state.area)) {
      const area = _.map(state.area, (a) => settings.general.geographic[a]);
      gaData = { ...gaData, area };
    }
    if (!_.isEmpty(state.eventType)) {
      const eventType = _.map(state.eventType, (a) => settings.general.eventType[a]);
      gaData = { ...gaData, 'activity type': eventType };
    }
    if (!_.isEmpty(state.search)) {
      gaData = { ...gaData, 'free search': state.search };
    }
    if (!_.isEmpty(state.date.fromDate) && !_.isEmpty(state.date.toDate)) {
      const d = `${format(parseISO(state.date.fromDate), 'dd/MM/yyyy')} - ${format(
        parseISO(state.date.toDate),
        'dd/MM/yyyy',
      )}`;
      gaData = { ...gaData, date: d };
    } else if (!_.isEmpty(state.date.fromDate)) {
      const d = `${format(parseISO(state.date.fromDate), 'dd/MM/yyyy')}`;
      gaData = { ...gaData, date: d };
    }

    dataLayerEvents.onFilterClick(JSON.stringify(gaData));
    onSearchBtnClick(state);
  };

  const onResetFilterClick = () => {
    dispatch({ type: 'resetFilters' });

    if (!_.isEqual(state, initialState)) {
      setResetDatePicker(true);
      onSearchBtnClick(initialState);
    }
  };

  const onSearchChange = (val: string, isFromAutocomplete = false) => {
    dispatch({ type: 'setSearch', payload: val });

    if (isFromAutocomplete) {
      onSearchBtnClick({ ...state, search: val });
    }
  };

  const onIsFreeEventsOnlyCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'setIsOnlyFreeEvents', payload: e.target.checked });
  };

  const onDateChange = (date: { fromDate: string; toDate: string }) => {
    setResetDatePicker(false);
    dispatch({ type: 'setDate', payload: date });
  };

  return (
    <div className={cn(css.filterWrapper, isIframe && css.isIframe, isRealMobile && css.isRealMobile, className)}>
      {isIframe && (
        <div className={css.iframeHeader}>
          <Icon type="ynet-logo-short" className={css.logo} />
          <div className={css.title}>{settings.general.iframeTitle}</div>
          <div className={css.subtitle}>{settings.general.iframeSubtitle}</div>
        </div>
      )}
      <div className={cn(css.fieldsWrapper, css.fieldsWrapperSelects)}>
        <div className={css.fieldsPreWrapper}>
          {isMobile && !isIframe ? (
            <div className={css.mobileFieldWrap}>
              <EFSelect
                className={css.field}
                placeholder={translate('placeholders.age')}
                selectAllLabel={translate('selectAllLabels.age')}
                value={state.age}
                onChange={onAgeChangeSelect}
                options={ageSelectOption}
                isIframe={isIframe}
              />
              <EFSelect
                className={css.field}
                placeholder={translate('placeholders.area')}
                selectAllLabel={translate('selectAllLabels.area')}
                value={state.area}
                onChange={onAreaChangeSelect}
                options={areaSelectOption}
                isIframe={isIframe}
              />
            </div>
          ) : (
            <>
              <EFSelect
                className={css.field}
                placeholder={translate('placeholders.age')}
                selectAllLabel={translate('selectAllLabels.age')}
                value={state.age}
                onChange={onAgeChangeSelect}
                options={ageSelectOption}
                isIframe={isIframe}
                name="age"
              />
              <EFSelect
                className={css.field}
                placeholder={translate('placeholders.area')}
                selectAllLabel={translate('selectAllLabels.area')}
                value={state.area}
                onChange={onAreaChangeSelect}
                options={areaSelectOption}
                isIframe={isIframe}
                name="area"
              />
            </>
          )}
          <EFSelect
            className={css.field}
            placeholder={translate('placeholders.eventType')}
            selectAllLabel={translate('selectAllLabels.eventType')}
            value={state.eventType}
            onChange={onEventTypeChangeSelect}
            options={activitySelectOption}
            isIframe={isIframe}
            name="eventType"
          />
        </div>

        <div className={css.datepicker}>
          <DatePicker
            iconColor={settings.design.iconsColor}
            isReset={isResetDatePicker}
            onChange={onDateChange}
            isIframe={isIframe}
            initialValue={state.date}
          />
        </div>

        <Search
          onSearchIconClick={onSearchClick}
          filterState={state}
          value={state.search}
          onChange={onSearchChange}
          isIframe={isIframe}
        />
      </div>
      <div className={css.fieldsWrapper}>
        <div className={css.right}>
          <Checkbox
            iconsColor={settings.design.iconsColor}
            borderColor={settings.design.borderColor}
            onChange={onIsFreeEventsOnlyCheckboxChange}
            label={translate('isOnlyFreeEventCheckboxLabel')}
            checked={state.isOnlyFreeEvents}
            className={css.isFreeCheckbox}
            isIframe={isIframe}
          />
          <button className={css.resetFilterBtn} onClick={onResetFilterClick}>
            {translate('resetBtnLabel')}
          </button>
        </div>
        <Button
          type="button"
          label={translate('searchBtnLabel')}
          onClick={onSearchClick}
          className={css.searchButton}
        />
      </div>
    </div>
  );
};
