export const parseSearch = (search) => {
  const queries = search.replace(/^\?/, '').split('&');
  const result = {};
  let field;
  let value;

  for (let i = 0; i < queries.length; i += 1) {
    [field, value] = queries[i].split('=');
    if (field) {
      result[field] = value;
    }
  }

  return result;
};
