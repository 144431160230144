import * as yup from 'yup';

import * as Settings from 'models/settings';
import { ValidationShape, ERROR as VALIDATION_ERROR } from 'endpoints/validation';

import { endpoint, EndpointError } from '../endpoint';

export type UpdateDesignParams = Settings.Model['design'];
export type UpdateDesignResult = Pick<Settings.Model, 'design'>;
export type UpdateDesignError = EndpointError;

export const updateDesignParamsSchema = yup.object().shape({
  filterSectionBg: yup.string().required(VALIDATION_ERROR.REQUIRED),
  iconsColor: yup.string().required(VALIDATION_ERROR.REQUIRED),
  borderColor: yup.string().required(VALIDATION_ERROR.REQUIRED),
  buttonsColor: yup.string().required(VALIDATION_ERROR.REQUIRED),
  desktopBg: yup
    .mixed()
    .test('mimetype', VALIDATION_ERROR.INVALID_FORMAT, (value, context) => {
      if (typeof value === 'string') {
        return true;
      }

      if (
        ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg+xml'].includes(value?.mimetype || value?.type)
      ) {
        return true;
      }

      return false;
    })
    .test('size', VALIDATION_ERROR.INVALID_SIZE, (value) => {
      if (typeof value === 'string') {
        return true;
      }

      if (value && value.size && value.size <= 1024 * 1024 * 3) {
        return true;
      }

      return false;
    })
    .required(VALIDATION_ERROR.REQUIRED),
  mobileBg: yup
    .mixed()
    .test('mimetype', VALIDATION_ERROR.INVALID_FORMAT, (value, context) => {
      if (typeof value === 'string') {
        return true;
      }

      if (
        ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg+xml'].includes(value?.mimetype || value?.type)
      ) {
        return true;
      }

      return false;
    })
    .test('size', VALIDATION_ERROR.INVALID_SIZE, (value) => {
      if (typeof value === 'string') {
        return true;
      }

      if (value && value.size && value.size <= 1024 * 1024 * 3) {
        return true;
      }

      return false;
    }),
} as ValidationShape<UpdateDesignParams>);

export const updateDesign = endpoint<UpdateDesignParams, UpdateDesignResult, UpdateDesignError>({
  method: 'POST',
  url: () => `/settings/design`,
});
