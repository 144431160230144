import * as React from 'react';
import classNames from 'classnames';

import Icon from './../Icon';
import css from './Button.module.scss';
import { useAppSelector } from 'client/hooks';
import { selectSettings } from 'client/redux/settings/selectors';

interface Props {
  className?: string;
  type: 'button' | 'submit' | 'reset';
  label: string;
  labelColor?: string;
  disabled?: boolean;
  iconType?: string;
  size?: 'default' | 'small';
  variant?: 'primary' | 'transparent';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<Props> = (props) => {
  const {
    className = '',
    label,
    type = 'button',
    size = 'default',
    labelColor = '#fff',
    disabled = false,
    iconType,
    variant = 'primary',
    ...rest
  } = props;
  const settings = useAppSelector(selectSettings);
  return (
    <button
      {...rest}
      style={{
        color: labelColor,
        backgroundColor: `${settings.design.buttonsColor}`,
      }}
      className={classNames(
        css.button,
        css[variant],
        css[size],
        disabled && css.disabled,
        iconType && css.withIcon,

        className,
      )}
      type={type}
    >
      <p className={css.label}>{label}</p>

      {iconType && <Icon type={iconType} className={classNames(css.icon)} />}
    </button>
  );
};

export default Button;
