export const CONFIG = JSON.parse(process.env.CONFIG || '{}');
export const REDUX_STORAGE_KEY = '__REDUX_STORAGE__';

export const LOCATIONS = [
  {
    value: '1',
    label: 'תל אביב',
  },
  {
    value: '2',
    label: 'ראשון לציון',
  },
];
