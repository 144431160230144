import React from 'react';
import ReactDOMClient from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import store from '../redux';

import 'src/firebase-client';

const Wrap = (
  <HelmetProvider>
    <Provider store={store}>
      <Router>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Router>
    </Provider>
  </HelmetProvider>
);

const container = document.getElementById('root');
if (container) ReactDOMClient.hydrateRoot(container, Wrap);
