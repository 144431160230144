import * as React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { useOnClickOutside, useAppSelector, useTranslation, useResponsive } from 'client/hooks';
import { selectSettings } from 'client/redux/settings/selectors';
import { isMobileOrTablet } from 'client/hooks/responsive';

import Icon from '../Icon';
import Button from '../Button';
import { CheckboxIcon } from '../CheckboxIcon';

import { reducer, initialState } from './reducer';

import css from './EFSelect.module.scss';

type EFSelectData = Array<{ value: number; label: string }>;
interface Props {
  className?: string;
  placeholder?: string;
  selectAllLabel?: string;
  value: number[];
  onChange: (data: number | number[]) => void;
  options: EFSelectData;
  isIframe?: boolean;
  name?: string;
}

const YLSelect: React.FC<Props> = ({
  className = '',
  value,
  placeholder = '',
  selectAllLabel = '',
  onChange,
  options,
  isIframe = false,
  name = '',
}) => {
  const [isMobile] = useResponsive('MOBILE');
  const { translate } = useTranslation('filter');
  const [state, dispatch] = React.useReducer(reducer, { ...initialState, draftChoice: value });
  const settings = useAppSelector(selectSettings);
  const { isOpened, isSelectAll } = state;
  const selectRef = React.useRef<HTMLDivElement>(null);
  const isRealMobile = isMobileOrTablet();

  const normalizedValue = React.useMemo(() => {
    const result = _.reduce(
      value,
      (acc: Array<string>, item) => {
        const data = options[item];

        acc.push(data.label);
        return acc;
      },
      [],
    );
    return result.join(', ');
  }, [value, options]);

  const handleClickOutside = () => {
    if (isMobile) {
      dispatch({ type: 'setDraftChoice', payload: value });
    }

    dispatch({ type: 'setIsOpened', payload: false });
  };

  useOnClickOutside<HTMLDivElement>(selectRef, handleClickOutside);

  React.useEffect(() => {
    if (isMobile && state.draftChoice.length === options.length) {
      dispatch({ type: 'setIsSelectAll', payload: true });
    } else {
      dispatch({ type: 'setIsSelectAll', payload: false });
    }
  }, [state.draftChoice, isMobile]);

  const onItemClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const { optionId } = e.currentTarget.dataset;

      if (isRealMobile) {
        dispatch({ type: 'setDraftChoice', payload: Number(optionId || '0') });
      } else {
        onChange(Number(optionId || '0'));
      }
    },
    [onChange, isRealMobile],
  );

  const onSelectAllClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const data: number[] = _.map(options, (o) => +o.value);

      if (isRealMobile) {
        if (!isSelectAll) {
          dispatch({ type: 'setDraftChoice', payload: data });
          dispatch({ type: 'setIsSelectAll', payload: true });
        } else {
          dispatch({ type: 'setDraftChoice', payload: -1 });
          dispatch({ type: 'setIsSelectAll', payload: false });
        }
      } else {
        if (value.length === options.length) {
          onChange([]);
        } else {
          onChange(data);
        }
      }
    },
    [onChange, isRealMobile],
  );

  const handleClickOnValue = React.useCallback(() => {
    dispatch({ type: 'setIsOpened', payload: !isOpened });
  }, [isOpened]);

  const onMobileCancelBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    dispatch({ type: 'setDraftChoice', payload: -1 });
  };

  const onMobileSubmitBtnClick = () => {
    onChange(state.draftChoice);

    dispatch({ type: 'setIsOpened', payload: false });
  };

  const onCloseBtnClick = () => {
    dispatch({ type: 'setIsOpened', payload: false });
  };

  const renderDropdownContent = () => {
    return (
      <>
        {isRealMobile && <div className={css.ddTitle}>{placeholder}</div>}
        {isIframe && isRealMobile && (
          <button onClick={onCloseBtnClick} className={css.closeButton}>
            <Icon type="iframe-close-icon" />
          </button>
        )}
        <div className={css.optionsList} style={isIframe ? renderIframeOptionsGrid(options) : {}}>
          <div className={css.option} onClick={onSelectAllClick}>
            <CheckboxIcon
              color={settings.design.iconsColor}
              checked={isRealMobile ? isSelectAll : value.length === options.length}
            />
            <p>{selectAllLabel}</p>
          </div>

          {_.map(options, (opt) => (
            <div key={opt.value} className={css.option} data-option-id={opt.value} onClick={onItemClick}>
              <CheckboxIcon
                color={settings.design.iconsColor}
                checked={
                  isRealMobile ? state.draftChoice.includes(opt.value) || false : value.includes(opt.value) || false
                }
              />
              <p>{opt.label}</p>
            </div>
          ))}
        </div>

        {isRealMobile && (
          <div className={css.actionButtons}>
            <Button
              className={css.approveBtn}
              onClick={onMobileSubmitBtnClick}
              type="button"
              label={translate('selectConfirmBtnLabel')}
            />
            <button onClick={onMobileCancelBtnClick} className={css.cancelBtn}>
              {translate('selectClearBtnLabel')}
            </button>
          </div>
        )}
      </>
    );
  };

  const renderIframeOptionsGrid = (options: EFSelectData) => {
    const size = _.size(options) + 1; // 1 - select all option

    if (isRealMobile) {
      return { gridTemplateColumns: `repeat(2fr, 1fr)`, gridTemplateRows: `repeat(${_.ceil(_.divide(size, 2))}, 1fr)` };
    }

    if (size > 9) {
      return { gridTemplateColumns: `repeat(3, 1fr)`, gridTemplateRows: `repeat(4, 1fr)` };
    } else {
      return { gridTemplateColumns: `repeat(${_.ceil(_.divide(size, 3))}, 1fr)` };
    }
  };

  const renderIframeDropdownClassName = (name: string) => {
    switch (name) {
      case 'age':
        return css.right;
      case 'area':
        return css.center;
      case 'eventType':
        return css.left;

      default:
        return css.left;
    }
  };

  return (
    <div
      className={cn(
        css.efSelect,
        isOpened && css.opened,
        !normalizedValue && css.empty,
        isIframe && css.isIframe,
        isRealMobile && css.isRealMobile,
        className,
      )}
      ref={selectRef}
    >
      <div className={css.valueContainer} onClick={handleClickOnValue}>
        <div className={css.value}>{normalizedValue || placeholder}</div>
        <div className={css.dropdownIndicator}>
          <Icon type="openArrow" color={settings.design.iconsColor} />
        </div>
      </div>
      {isOpened && (
        <div
          className={cn(
            css.dropdownWrap,
            renderIframeDropdownClassName(name),
            _.size(options) + 1 > 9 && css.smallSpaces,
          )}
        >
          {isRealMobile && <div className={css.dropdownBg} onClick={handleClickOutside} />}
          <div className={css.dropdown}>{renderDropdownContent()}</div>
        </div>
      )}
    </div>
  );
};

export default YLSelect;
