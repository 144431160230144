import React from 'react';
import cn from 'classnames';

import css from './Loader.module.scss';

interface Props {
  className?: string;
}

export const Loader: React.FC<Props> = ({ className = '' }) => {
  return (
    <div className={cn(css.loader, className)}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
