import _ from 'lodash';

export interface State {
  isOpened: boolean;
  isSelectAll: boolean;
  draftChoice: number[];
}

export const initialState: State = {
  isOpened: false,
  isSelectAll: false,
  draftChoice: [],
};

export type Action =
  | { type: 'setIsOpened'; payload: State['isOpened'] }
  | { type: 'setIsSelectAll'; payload: State['isSelectAll'] }
  | { type: 'setDraftChoice'; payload: number | State['draftChoice'] };

export function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'setIsOpened':
      return { ...state, isOpened: action.payload };
    case 'setIsSelectAll':
      return { ...state, isSelectAll: action.payload };

    case 'setDraftChoice':
      if (_.isArray(action.payload)) {
        return { ...state, draftChoice: action.payload };
      } else {
        if (action.payload === -1) {
          return { ...state, draftChoice: [] };
        }

        const next = [...state.draftChoice];
        const existingValueIndex = next.indexOf(action.payload);

        if (existingValueIndex >= 0) {
          next.splice(existingValueIndex, 1);
        } else {
          next.push(action.payload);
        }

        return { ...state, draftChoice: next };
      }

    default:
      return { ...state };
  }
}
