import * as yup from 'yup';

import { endpoint, EndpointError, ERROR as ENDPOINT_ERROR } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type SessionInParams = { idToken: string; csrfToken: string };
export type SessionInResult = { message: string };
export type SessionInError = EndpointError | { code: ENDPOINT_ERROR.UNATHORIZED_REQUEST };

export const sessionInParamsSchema = yup.object().shape({
  idToken: yup.string().required(VALIDATION_ERROR.REQUIRED),
  csrfToken: yup.string().required(VALIDATION_ERROR.REQUIRED),
} as ValidationShape<SessionInParams>);

export const sessionIn = endpoint<SessionInParams, SessionInResult, SessionInError>({
  method: 'POST',
  url: () => '/auth/session-in',
});

export type SessionOutParams = void;
export type SessionOutResult = { message: string };
export type SessionOutError = { code: ENDPOINT_ERROR.UNEXPECTED_ERROR; details: unknown };
export const sessionOut = endpoint<SessionOutParams, SessionOutResult, SessionOutError>({
  method: 'POST',
  url: () => '/auth/session-out',
});
