import * as yup from 'yup';

import { Client } from 'models/index';

import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type SignInParams = Client.Model & { anonymousUid?: string };
export type SignInResult = Client.Model & { token: string };
export type SignInError = EndpointError; // TODO: refactor endpoint errors, get rid of constants

export const signInParamsSchema = yup.object().shape({
  uid: yup.string().required(VALIDATION_ERROR.REQUIRED),
  anonymousUid: yup.string(),
  email: yup.string().email(VALIDATION_ERROR.INVALID_VALUE),
  phone: yup.string().min(7, VALIDATION_ERROR.TOO_SHORT).max(13, VALIDATION_ERROR.TOO_LONG),
  name: yup.string().min(2, VALIDATION_ERROR.TOO_SHORT),
  pictureURL: yup.string(),
} as ValidationShape<SignInParams>);

export const signIn = endpoint<SignInParams, SignInResult, SignInError>({
  method: 'POST',
  url: () => `/auth/client`,
});
