import { RootState } from '..';
import { createSelector } from 'reselect';

export const selectIsLoggedIn = (state: RootState) => {
  return state.client.auth.isLoggedIn;
};

export const selectUserData = (state: RootState) => {
  return state.client;
};

export const selectUser = (state: RootState) => {
  return state.client;
};

export const selectShortName = createSelector(selectUser, (client) => {
  if (client.name) {
    const splittedName = client.name.split(' ');

    return splittedName.length > 1
      ? `${splittedName[0].toUpperCase().charAt(0)}${splittedName[1].toUpperCase().charAt(0)}`
      : client.name[0].toUpperCase();
  } else if (client.email) {
    const splittedName = client.email.split('@')[0].split('.');

    return splittedName.length > 1
      ? `${splittedName[0].toUpperCase()}.${splittedName[1].toUpperCase()}`
      : client.email[0].toUpperCase();
  } else if (client.phone) {
    return client.phone.substr(client.phone.length - 3);
  }

  return null;
});
