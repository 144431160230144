import { Admin } from 'models/index';
import { createParamsSchema } from './create';
import { endpoint, EndpointError } from '../endpoint';

export type UpdateParams = Omit<Admin.IAdmin, '_id'>;
export type UpdateResult = Admin.IAdmin;
export type UpdateError = EndpointError;

export const updateParamsSchema = createParamsSchema;

export const update = endpoint<UpdateParams, UpdateResult, UpdateError>({
  method: 'PUT',
  url: () => `/admin`,
});
