import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Datepicker, localeHe, CalendarPrev, CalendarNav, CalendarNext } from '@mobiscroll/react';
import cn from 'classnames';

import { CAN_USE_DOM } from 'client/constants';
import { useResponsive } from 'client/hooks';
import { isMobileOrTablet } from 'client/hooks/responsive';
import { FiltersState } from '../Filter/reducer';
import Icon from '../Icon';

import './Datepicker.scss';
import css from './Datepicker.module.scss';

interface Props {
  className?: string;
  iconColor: string;
  isReset: boolean;
  onChange: (data: { fromDate: string; toDate: string }) => void;
  initialValue: FiltersState['date'];
  isIframe?: boolean;
}

const DatePicker: React.FC<Props> = (props) => {
  const { className = '', iconColor = '', isReset, onChange, isIframe = false, initialValue } = props;
  const [isMobile] = useResponsive(['MOBILE', 'TABLET']);
  const [instance, setInstance] = React.useState(null);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const isRealMobile = isMobileOrTablet();
  const dateFormat = isIframe ? 'dd/MM/yy' : 'dd/MM/yyyy';
  const datepickerFormat = isIframe ? 'DD/MM/YY' : 'DD/MM/YYYY';

  React.useEffect(() => {
    const dateValue =
      initialValue.toDate === '' && initialValue.fromDate === ''
        ? ''
        : initialValue.toDate === ''
        ? `${format(parseISO(initialValue.fromDate), dateFormat)}`
        : `${format(parseISO(initialValue.fromDate), dateFormat)} - ${format(
            parseISO(initialValue.toDate),
            dateFormat,
          )}`;
    setValue(dateValue);
  }, [initialValue]);

  React.useEffect(() => {
    if (instance !== null && isReset) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      instance.state.value = ['', ''];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      instance._input.value = '';
    }
  }, [instance, isReset]);

  const onDateChange = (e: any) => {
    if (e.value[0] && e.value[1]) {
      const fromISOString = new Date(e.value[0]).toISOString();
      const toISOString = new Date(e.value[1]).toISOString();

      onChange({ fromDate: fromISOString, toDate: toISOString });
      setValue(`${format(parseISO(e.value[0]), dateFormat)} - ${format(parseISO(e.value[1]), dateFormat)}`);
    } else if (e.value[0] && !e.value[1]) {
      const fromISOString = new Date(e.value[0]).toISOString();

      onChange({ fromDate: fromISOString, toDate: '' });
      setValue(`${format(parseISO(e.value[0]), dateFormat)}`);
    }
  };

  const calendarHeader = () => {
    return (
      <React.Fragment>
        <CalendarPrev className="custom-prev" />
        <CalendarNav className="custom-nav" />
        <CalendarNext className="custom-next" />
      </React.Fragment>
    );
  };

  const closeDatepicker = () => {
    if (instance !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      instance.s.onClose();
      const el = document.getElementsByClassName('mbsc-popup-overlay');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      el[0].click();
      setIsOpened(false);
    }
  };

  return (
    <div
      className={cn(
        css.datepickerComponent,
        isOpened && css.isOpened,
        isIframe && css.isIframe,
        isRealMobile && css.isRealMobile,
      )}
    >
      <Icon
        type={isMobile ? 'openArrow' : 'datepicker'}
        color={iconColor}
        className={cn(css.datepickerIcon, isIframe && css.isIframe)}
      />
      {isIframe && isRealMobile && isOpened && <div className={css.dropdownBg} />}
      {isIframe && isRealMobile && isOpened && (
        <Icon type="iframe-close-icon" className={css.closeIcon} onClick={closeDatepicker} />
      )}
      {CAN_USE_DOM && (
        <Datepicker
          cssClass={cn(css.datepicker, isIframe && css.isIframe, isRealMobile && css.isRealMobile)}
          ref={(r: any) => setInstance(r)}
          controls={['calendar']}
          display="anchored"
          animation={isRealMobile}
          select="range"
          rtl={true}
          showRangeLabels={false}
          onOpen={() => setIsOpened(true)}
          onClose={() => setIsOpened(false)}
          touchUi={false}
          locale={localeHe}
          inputComponent="input"
          inputProps={{
            placeholder: 'טווח תאריכים',
            className: css.datepickerInput,
            value: value || '',
          }}
          dateFormat={datepickerFormat}
          returnFormat="iso8601"
          min="today"
          theme="material"
          themeVariant="light"
          renderCalendarHeader={calendarHeader}
          onChange={(event) => onDateChange(event)}
        />
      )}
    </div>
  );
};

export default DatePicker;
