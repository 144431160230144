import { CAN_USE_DOM, IS_DEVEL } from 'client/constants';

declare global {
  interface Window {
    dataLayer: any[];
    googletag: any;
    opera: string;
  }
}

export const dataLayerEvents = {
  onFilterClick: (options: string) =>
    !IS_DEVEL &&
    CAN_USE_DOM &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'GA_events',
      Category: 'Childrens events',
      Action: 'search',
      Label: options,
    }),
  onExternalLinkClick: (title: string, label: string) =>
    !IS_DEVEL &&
    CAN_USE_DOM &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'GA_events',
      Category: 'Childrens events',
      Action: label,
      Label: title,
    }),
};
