import { CAN_USE_DOM } from 'client/constants';

export function isIOS() {
  if (!CAN_USE_DOM) {
    return false;
  }
  let check = false;
  (function run(a) {
    if (/iPhone|iPad|iPod/i.test(a)) check = true;
  })(navigator.userAgent);
  return check;
}
