import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth, signInWithCustomToken, inMemoryPersistence } from 'firebase/auth';

import * as auth from 'endpoints/auth/client';
import { sessionIn } from 'endpoints/auth';
import { ApiResponse } from 'endpoints/endpoint';
import { app as firebaseApp } from 'src/firebase-client';
import { ANONYMOUS_UID } from 'client/constants';
import { RootState } from '../..';

export const signIn = createAsyncThunk<
  ApiResponse<auth.SignInResult, auth.SignInError>,
  auth.SignInParams,
  { rejectValue: string }
>('admin/signIn', async (data, { rejectWithValue, getState }) => {
  try {
    const client = (getState() as RootState).client;
    const response = await auth
      .signIn({
        ...data,
        anonymousUid: client.uid.includes(ANONYMOUS_UID) ? client.uid : undefined,
      })
      .invoke();

    if (response.success) {
      const firebaseAuth = getAuth(firebaseApp);

      await firebaseAuth.setPersistence(inMemoryPersistence);
      signInWithCustomToken(firebaseAuth, response.token).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.info(errorCode, errorMessage);
      });

      const idToken = await firebaseAuth.currentUser?.getIdToken(/* forceRefresh */ true);
      const csrfToken = client.csrfToken;

      if (idToken && csrfToken) {
        await sessionIn({ idToken, csrfToken }).invoke();
        await firebaseAuth.signOut();

        return response;
      }
    }
    throw new Error('SignInError');
  } catch (e) {
    return rejectWithValue((e as Error).message);
  }
});
