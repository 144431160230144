import React from 'react';
import classNames from 'classnames';
import cn from 'classnames';

import { CheckboxIcon } from '../CheckboxIcon';

import css from './Checkbox.module.scss';

interface Props {
  className?: string;
  label: string;
  iconsColor: string;
  borderColor?: string;
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isIframe?: boolean;
}

const Checkbox: React.FC<Props> = (props) => {
  const { className = '', label, iconsColor, borderColor, checked = false, onChange, isIframe = false } = props;

  return (
    <div className={cn(css.checkboxWrapper, isIframe && css.isIframe, className)}>
      <div className={css.checkBoxContent}>
        <label className={classNames(css.checkBoxContentLabel, checked && css.checkboxChecked)}>
          <input type="checkbox" onChange={onChange} checked={checked} />
          <CheckboxIcon color={iconsColor} borderColor={borderColor} checked={checked} />
          <p>{label}</p>
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
