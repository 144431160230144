import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import fetch from 'isomorphic-fetch';

import { CONFIG } from 'constants/index';

const api = createApi({
  reducerPath: 'api',
  tagTypes: ['EVENTS', 'EVENTS_FOR_AUTOCOMPLETE'],
  baseQuery: fetchBaseQuery({ baseUrl: CONFIG.api, fetchFn: fetch }),
  extractRehydrationInfo(action) {
    if (action.type === 'HYDRATE') {
      return action.payload;
    }
  },
  endpoints: () => ({}),
});

export default api;
