export interface State {
  age: number[];
  area: number[];
  search: string;
  eventType: number[];
  isOnlyFreeEvents: boolean;
  date: { fromDate: string; toDate: string };
}

export type FiltersState = State;

export const initialState: State = {
  age: [],
  area: [],
  search: '',
  eventType: [],
  isOnlyFreeEvents: false,
  date: { fromDate: '', toDate: '' },
};

export type Action =
  | { type: 'setAge'; payload: State['age'] }
  | { type: 'setSearch'; payload: State['search'] }
  | { type: 'resetFilters' }
  | { type: 'setArea'; payload: State['area'] }
  | { type: 'setIsOnlyFreeEvents'; payload: State['isOnlyFreeEvents'] }
  | { type: 'setEventType'; payload: State['eventType'] }
  | { type: 'setDate'; payload: State['date'] }
  | { type: 'setAll'; payload: State };

export function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'setAge':
      return { ...state, age: action.payload };

    case 'setArea':
      return { ...state, area: action.payload };

    case 'setSearch':
      return { ...state, search: action.payload };

    case 'setEventType':
      return { ...state, eventType: action.payload };

    case 'setDate':
      return { ...state, date: action.payload };

    case 'resetFilters':
      return {
        ...state,
        eventType: [],
        age: [],
        area: [],
        search: '',
        date: { fromDate: '', toDate: '' },
        isOnlyFreeEvents: false,
      };

    case 'setAll':
      return {
        ...state,
        ...action.payload,
      };

    case 'setIsOnlyFreeEvents':
      return { ...state, isOnlyFreeEvents: action.payload };

    default:
      return { ...state };
  }
}
