import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { useAppSelector, useOnClickOutside, useTranslation } from 'client/hooks';
import { isMobileOrTablet } from 'client/hooks/responsive';
import { selectSettings } from 'client/redux/settings/selectors';
import { eventsAPI } from 'client/redux/api/events';

import Icon from '../Icon';
import { FiltersState } from '../Filter/reducer';

import css from './Search.module.scss';

interface Props {
  filterState: FiltersState;
  className?: string;
  value: string;
  onSearchIconClick: () => void;
  onChange: (val: string, isFromAutocomplete?: boolean) => void;
  isIframe?: boolean;
}

export const Search: React.FC<Props> = ({
  className = '',
  onChange,
  onSearchIconClick,
  value,
  filterState,
  isIframe = false,
}) => {
  const settings = useAppSelector(selectSettings);
  const { translate } = useTranslation('filter');
  const searchTimeout = React.useRef<NodeJS.Timeout>();
  const searchRef = React.useRef<HTMLDivElement>(null);
  const [isSuggestionsListVisible, setIsSuggestionsListVisible] = React.useState(false);
  const isRealMobile = isMobileOrTablet();
  const [trigger, { data }] = eventsAPI.endpoints.getEventsListForAutoComplete.useLazyQuery();
  const events = React.useMemo(() => {
    if (data?.success) {
      return value ? _.filter(data.events, (evt) => evt.title.includes(value)) : [];
    }

    return [];
  }, [data, value]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (searchTimeout.current) clearTimeout(searchTimeout.current);

    onChange(e.target.value);

    searchTimeout.current = setTimeout(() => {
      trigger({ ...filterState, search: e.target.value });
    }, 400);
  };

  const handleClickOutside = () => {
    setIsSuggestionsListVisible(false);
  };

  useOnClickOutside<HTMLDivElement>(searchRef, handleClickOutside);

  const onInputFocus = () => {
    setIsSuggestionsListVisible(true);
  };

  const handelSearchIconClick = () => {
    onSearchIconClick();
    setIsSuggestionsListVisible(false);
  };

  const onSuggestedItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { eventName } = e.currentTarget.dataset;

    onChange(eventName as string, true /* isFromAutocomplete */);
    setIsSuggestionsListVisible(false);
  };

  const onSuggestionsClose = () => {
    setIsSuggestionsListVisible(false);
  };

  return (
    <div
      className={cn(css.search, isIframe && css.isIframe, isRealMobile && css.isRealMobile, className)}
      ref={searchRef}
    >
      {isIframe && isRealMobile && isSuggestionsListVisible && events.length !== 0 && (
        <div className={css.dropdownBg} />
      )}
      <div className={css.inputWrap}>
        <input
          type="text"
          placeholder={translate('placeholders.search')}
          onFocus={onInputFocus}
          value={value}
          onChange={onSearchChange}
        />
        <button type="button" className={css.searchIconBtn} onClick={handelSearchIconClick}>
          <Icon className={css.searchIcon} color={settings.design.iconsColor} type="search" />
        </button>
      </div>

      {isSuggestionsListVisible && events.length !== 0 && (
        <div className={css.suggestionsList}>
          {isIframe && <Icon type="iframe-close-icon" className={css.closeIcon} onClick={onSuggestionsClose} />}
          <div className={css.suggestionsListInner}>
            {_.map(events, (ev) => (
              <div
                onClick={onSuggestedItemClick}
                className={css.item}
                data-event-name={ev.title}
                dangerouslySetInnerHTML={{ __html: getStrWithHighlightCharacters(ev.title, value) }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const getStrWithHighlightCharacters = (text: string, word: string) => {
  const substring = new RegExp(word, 'gi');

  return text.replace(substring, (match) => `<span>${match}</span>`);
};
