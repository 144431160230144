import * as React from 'react';
import cn from 'classnames';

import Icon from '../Icon';

import css from './Header.module.scss';
import { useResponsive } from 'client/hooks';

interface Props {
  className?: string;
  themeMode: string;
}

export const Header: React.FC<Props> = (props) => {
  const [isMobile] = useResponsive(['MOBILE', 'TABLET']);
  const { className = '', themeMode } = props;
  const ynetLink = 'https://www.ynet.co.il';

  return (
    <header className={cn(css.header, className)}>
      {!isMobile ? (
        <iframe
          src="https://z.ynet.co.il/short/commerce/2021/links/header/"
          title="header"
          style={{ border: '0', width: '100%', display: 'block', margin: '0 auto' }}
        ></iframe>
      ) : (
        <a href={ynetLink} className={css.headerLogo} target="_blank" rel="noopener noreferrer">
          <Icon type="ynet-logo" />
        </a>
      )}
    </header>
  );
};
