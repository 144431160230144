import { Event } from 'models/index';

import { endpoint, EndpointError } from '../endpoint';

export type GetParams = void;
export type GetResult = { events: Event.Model[] };
export type GetError = EndpointError;

export const get = endpoint<GetParams, GetResult, GetError>({
  method: 'GET',
  url: () => '/event',
});
